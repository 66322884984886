import { createGlobalStyle } from 'styled-components'

import { reset } from 'styled-reset'

const GlobalStyles = createGlobalStyle`
  ${reset} 

  @font-face {
    font-family: 'Noto Sans KR';
    font-weight: 300;
    src: url(${process.env.PUBLIC_URL}/font/NotoSansKR-Light.otf) format('woff2'),
      url(${process.env.PUBLIC_URL}/font/NotoSansKR-Light.otf) format('woff'),
      url(${process.env.PUBLIC_URL}/font/NotoSansKR-Light.otf) format('truetype');
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-weight: 400;
    src: url(${process.env.PUBLIC_URL}/font/NotoSansKR-Regular.otf) format('woff2'),
      url(${process.env.PUBLIC_URL}/font/NotoSansKR-Regular.otf) format('woff'),
      url(${process.env.PUBLIC_URL}/font/NotoSansKR-Regular.otf) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans KR';
    font-weight: 500;
    src: url(${process.env.PUBLIC_URL}/font/NotoSansKR-Medium.otf) format('woff2');
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    src: url(${process.env.PUBLIC_URL}/font/NotoSansKR-Bold.otf) format('woff2'),
      url(${process.env.PUBLIC_URL}/font/NotoSansKR-Bold.otf) format('woff'),
      url(${process.env.PUBLIC_URL}/font/NotoSansKR-Bold.otf) format('truetype');
  }

  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
  }

  body {
    font-family: 'Noto Sans KR';
    font-style: normal;
    width: 100%;
    // height: 100vh;
    // background: black url(${process.env.PUBLIC_URL}/img/main/main-background3.png) no-repeat center top;

    background: #fff;
  }

  a {
    text-decoration: none;
  }

`

export default GlobalStyles
