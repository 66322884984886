import { AccountButton, LoginForm } from '@/shared/components/account/AccountElements'
import Error from '@/shared/components/form/Error'
import {
  FormFieldButton,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import EyeIcon from 'mdi-react/EyeIcon'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { loginValidate } from '../../../utils/validate/authValidate'

const LogInForm = ({ onSubmit }) => {
  const [passwordShown, setPasswordShown] = useState(false)
  return (
    <Form onSubmit={onSubmit} validate={loginValidate}>
      {({ handleSubmit }) => (
        <LoginForm onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupLabel>아이디</FormGroupLabel>
            <FormGroupField>
              <Field name="userId">
                {({ input, meta }) => (
                  <FormInputWrap>
                    <input
                      {...input}
                      type="text"
                      value={input.value}
                      autoComplete="new-password"
                      onChange={e => {
                        input.onChange(e)
                      }}
                      placeholder="아이디를 입력하세요"
                    />
                    {meta.touched && meta.error && <Error error={meta.error} />}
                  </FormInputWrap>
                )}
              </Field>
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>패스워드</FormGroupLabel>
            <FormGroupField>
              <Field name="password">
                {({ input, meta }) => (
                  <>
                    <FormInputWrap>
                      <input
                        {...input}
                        type={passwordShown ? 'text' : 'password'}
                        value={input.value}
                        inputMode="latin"
                        onChange={e => {
                          input.onChange(e)
                        }}
                        autoComplete="new-password"
                        placeholder="비밀번호를 입력하세요"
                      />
                      {meta.touched && meta.error && <Error error={meta.error} />}
                    </FormInputWrap>
                    <FormFieldButton
                      active={passwordShown}
                      type="button"
                      onClick={() => setPasswordShown(shown => !shown)}
                    >
                      <EyeIcon />
                    </FormFieldButton>
                  </>
                )}
              </Field>
            </FormGroupField>
          </FormGroup>
          <AccountButton type="submit" variant="primary">
            로그인
          </AccountButton>
        </LoginForm>
      )}
    </Form>
  )
}

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default LogInForm
