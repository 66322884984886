export const loginValidate = values => {
  const errors = {}

  if (!values.userId) {
    errors.userId = '필수 입력입니다.'
  }

  if (!values.password) {
    errors.password = '필수 입력입니다.'
  }
  return errors
}
