import React, { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { FaArrowUp } from 'react-icons/fa'
import { colorScrollButtonBackground, colorScrollButtonBorder, colorScrollButtonHover } from '../../utils/palette'

const ScrollButton = ({ isMobile }) => {
  const [scrolling, setScrolling] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolling(true)
      } else {
        setScrolling(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {/* <a href="https://open.kakao.com/o/ss47ATNg" target="_blank" rel="noreferrer">
        <KakaoIcon src={`${process.env.PUBLIC_URL}/img/main/kakao.png`} alt="" isMobile={isMobile} />
      </a> */}
      <KakaoIcon
        src={`${process.env.PUBLIC_URL}/img/main/kakao.png`}
        alt=""
        isMobile={isMobile}
        onClick={() => {
          window.location.href = 'https://open.kakao.com/o/ss47ATNg'
        }}
      />
      <CustomerIcon src={`${process.env.PUBLIC_URL}/img/main/customer-icon.png`} alt="" isMobile={isMobile} />
      <SmothScroll scrolling={scrolling} onClick={scrollToTop} isMobile={isMobile}>
        <img src={`${process.env.PUBLIC_URL}/img/main/btn_top.png`} alt="" />
      </SmothScroll>
    </>
  )
}

export default ScrollButton

const ScrollButtonWrap = styled.div``

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const SmothScroll = styled.div`
  position: fixed;
  right: ${props => (props.isMobile ? '25px' : '80px')};
  bottom: ${props => (props.isMobile ? '55px' : '275px')};
  width: 42px;
  height: 42px;
  outline: none;
  cursor: pointer;
  border: none;
  background: #484848;
  // display: block;
  display: ${props => (props.scrolling ? 'block' : 'none')};

  ${({ scrolling }) =>
    scrolling
      ? css`
          animation: ${fadeIn} 1s forwards;
        `
      : css`
          animation: ${fadeOut} 1s forwards;
        `}
  z-index: 999;

  &:hover {
    background: ${colorScrollButtonHover};
    color: rgba(255, 192, 26, 1);
  }
`

const KakaoIcon = styled.img`
  width: ${props => (props.isMobile ? '150px' : '250px')};
  height: ${props => (props.isMobile ? '50px' : '85px')};
  position: fixed;
  right: ${props => (props.isMobile ? '25px' : '80px')};
  bottom: ${props => (props.isMobile ? '0' : '100px')};
  z-index: 999999999;
  &:hover {
    cursor: pointer;
  }
`

const CustomerIcon = styled.img`
  width: ${props => (props.isMobile ? '150px' : '250px')};
  height: ${props => (props.isMobile ? '50px' : '85px')};
  position: fixed;
  right: ${props => (props.isMobile ? '175px' : '80px')};
  bottom: ${props => (props.isMobile ? '0' : '185px')};
  z-index: 999999999;
  &:hover {
    cursor: pointer;
  }
`
