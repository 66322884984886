import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 간단 상담 신청
export async function applySimpleCounseling(body) {
  const response = await axios({
    url: '/api/v1/counseling/simple',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 상담 신청
export async function applyCounseling(body) {
  const response = await axios({
    url: '/api/v1/counseling',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 상담 현황 조회
export async function searchCounseling(params) {
  const response = await axios({
    url: '/api/v1/counseling',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 상담 조회
export async function searchCounselingByAdmin(params) {
  const response = await axios({
    url: '/api/v1/counseling/admin',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchCounselingAdmin(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchCounselingAdmin() {
    await searchCounselingByAdmin(params).then(res => {
      setTotalElement(res.data.totalElement)
      setContent(res.data.content)
    })
  }

  useEffect(() => {
    fetchSearchCounselingAdmin()
  }, [params])

  return { totalElement, content, fetchSearchCounselingAdmin }
}
