import moment from 'moment-timezone'

/**
 * @param {date} Date
 * @returns 한국 시간으로 변경된 날짜
 */
export const convertToKst = (date, format = null) => {
  return moment.tz(date, 'Asia/Seoul').format(format)
}

export const convertToKstDate = date => {
  if (!date) {
    return '-'
  }
  return moment.tz(date, 'Asia/Seoul').format('YYYY-MM-DD')
}

export const convertToKstSliderDate = date => {
  if (!date) {
    return date
  }
  return moment.tz(date, 'Asia/Seoul').format('YYYY-MM-DD HH:mm')
}

export const convertToKstDateTime = date => {
  if (!date) {
    return '-'
  }
  return moment.tz(date, 'Asia/Seoul').format('YYYY-MM-DD  HH:mm:ss')
}

export const minusDayToKst = minusDay => {
  const date = new Date()
  date.setDate(date.getDate() - minusDay)
  return convertToKst(date)
}
