import { removeToken, setCookie } from '../../utils/cookie'
import axios from '../base/axios'

// 로그인
export async function siginIn(body) {
  const response = await axios({
    url: '/api/v1/auth/sign-in',
    method: 'POST',
    data: body,
  }).then(res => {
    setCookie('accessToken', res.data.accessToken, {
      path: '/',
      secure: true,
      sameSite: 'none',
    })

    setCookie('refreshToken', res.data.refreshToken, {
      path: '/',
      secure: true,
      sameSite: 'none',
    })

    return res
  })
  return response
}

// 로그아웃
export async function siginOut() {
  const response = await axios({
    url: '/api/v1/auth/sign-out',
    method: 'POST',
  }).then(res => {
    // cookie 에 refresh token 삭제
    removeToken()
    return res
  })

  return response
}
