import styled from 'styled-components'

export const PopupHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PopupBody = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  // border: 1px solid black;
  // box-shadow: 5px 4px 3px 2px rgb(0 1 0 / 30%);
`

export const MobilePopupBody = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  // border: 1px solid black;
  // box-shadow: 5px 4px 3px 2px rgb(0 1 0 / 30%);
`

export const PopupImgWrap = styled.div`
  width: 100%;
  height: 100%;
  // 팝업 이미지는 400 x 600 으로 해야 함
`

export const PopupImgItem = styled.img`
  width: 100%;
  height: 100%;
  // height: 500px;
`

export const MobilePopupImgItem = styled.img`
  width: 100%;
  height: 100%;
`

export const CloseButtonWrap = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 34px;

  background: linear-gradient(180deg, #000000 0%, #444444 100%);
  &:hover {
    cursor: pointer;
    background: black;
  }
  border: 1px solid #6f6f6f;
`

export const CloseButton = styled.span`
  position: absolute;
  width: 113px;
  height: 20px;
  left: calc(50% - 113px / 2 - 0.5px);
  top: calc(50% - 20px / 2 - 1px);

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  /* 엘로우폰트 */

  color: white;
`

// export const PopupWrapper = styled.div`
//   border: solid 3px red;
//   box-sizing: border-box;
//   display: ${props => (props.visible ? 'block' : 'none')};
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 1000;
//   overflow: auto;
//   outline: 0;
// `

export const PopupWrapper = styled.div`
  padding-top: 40px;
  box-sizing: border-box;
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  position: fixed;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`

export const MobilePopupWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`

export const PopupOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`

export const PopupContent = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none !important')};
  position: absolute;
  width: 500px;
  top: 150px;
  left: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const MobilePopupContent = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none !important')};
  position: absolute;
  width: 100%;
  max-width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
