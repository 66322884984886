import React from 'react'
import { CounselingInterestedEvent, CounselingStatus, CounselingType } from '../../utils/enums/counselingEnum'
import { convertToKstSliderDate } from '../../utils/dateTime'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const CustomerManageData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
    },
    {
      Header: '이름',
      accessor: 'userName',
    },
    {
      Header: '연락처',
      accessor: 'userPhoneNum',
    },
    {
      Header: '이메일',
      accessor: 'userEmail',
    },
    {
      Header: '관심 종목',
      accessor: 'interestedEvent',
      Cell: r => {
        const { row } = r
        const { original } = row
        return CounselingInterestedEvent[original.interestedEvent]
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      Cell: r => {
        const { row } = r
        const { original } = row
        return CounselingStatus[original.status]
      },
    },
    {
      Header: '타입',
      accessor: 'counselingType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return CounselingType[original.counselingType]
      },
    },
    {
      Header: '신청일',
      accessor: 'createAt',
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstSliderDate(original.createAt)
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default CustomerManageData
