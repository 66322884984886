import PropTypes from 'prop-types'
import React from 'react'
import { useFlexLayout, usePagination, useResizeColumns, useRowSelect, useSortBy, useTable } from 'react-table'
import styled from 'styled-components'
import { scrollbarStyles } from '../../../utils/palette'
import { Table } from '../TableElements'

import BodyReactTable from './ReactTableBody'
import ReactTableHeader from './ReactTableHeader'
import ReactTablePagination from './ReactTablePagination'

const ReactTableBase = ({ tableConfig, columns, data }) => {
  const {
    isResizable,
    isSortable,
    withPagination,

    // 새로 추가된 것들
    _pageIndex,
    _pageSize,
    totalElement,
    _setPageIndex,
    onClickRowHandler,
    expandedRow,
  } = tableConfig

  const getPageCount = () => {
    if (totalElement <= _pageSize) {
      return 1
    }

    if (parseInt(totalElement % _pageSize, 10) === 0) {
      return parseInt(totalElement / _pageSize, 10)
    }
    return parseInt(totalElement / _pageSize, 10) + 1
  }

  const tableOptions = {
    columns,
    data,
    defaultColumn: {},
    dataLength: data.length,
    autoResetPage: false,
    disableSortBy: !isSortable,
    manualSortBy: !isSortable,
    // 페이지 네이션 사용하는 곳은 true, 아닌곳은 false
    manualPagination: withPagination,
    initialState: {
      pageIndex: _pageIndex,
      pageSize: _pageSize,
    },
    pageCount: getPageCount(),
  }

  let tableOptionalHook = []
  if (isResizable) tableOptionalHook = [useFlexLayout]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    page,
    // pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    // dataLength,
    state: { pageIndex, pageSize },
  } = useTable(tableOptions, useSortBy, usePagination, useResizeColumns, useRowSelect, ...tableOptionalHook)

  return (
    <div>
      <TableWrap pagination={withPagination}>
        <Table {...getTableProps()} bordered responsive>
          <ReactTableHeader headerGroups={headerGroups} isSortable={isSortable} isResizable={isResizable} />
          <BodyReactTable
            headerGroups={headerGroups}
            page={rows}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            onClickRowHandler={onClickRowHandler}
            expandedRow={expandedRow}
          />
        </Table>
      </TableWrap>
      {withPagination && rows.length > 0 && (
        <ReactTablePagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          setPageIndex={_setPageIndex}
        />
      )}
    </div>
  )
}

export default ReactTableBase

ReactTableBase.propTypes = {
  tableConfig: PropTypes.shape({
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    withPagination: PropTypes.bool,
    _pageIndex: PropTypes.number,
    _pageSize: PropTypes.number,
    totalElement: PropTypes.number,
    _setPageIndex: PropTypes.func,
    onClickRowHandler: PropTypes.func,
    expandedRow: PropTypes.number,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.shape()),
}

ReactTableBase.defaultProps = {
  tableConfig: {
    isResizable: false,
    isSortable: false,
    withPagination: false,
    _pageIndex: 0,
    _pageSize: 999999,
    totalElement: 1,
    _setPageIndex: null,
    onClickRowHandler: null,
    expandedRow: null,
  },
  columns: [
    { Header: '#', accessor: 'id' },
    { Header: 'Header Example Title one', accessor: 'first' },
    { Header: 'Header Example Title two', accessor: 'last' },
  ],
  data: [
    { id: 1, first: 'Cell Example Data one', last: 'Cell Example Data two' },
    { id: 2, first: 'Cell Example Data three', last: 'Cell Example Data four' },
  ],
}

const TableWrap = styled.div`
  overflow-x: auto;

  ${scrollbarStyles};

  ${props =>
    props.pagination
      ? `
    margin-bottom: 1rem;
  `
      : `
    //  height: 458px;
     
  
    tbody {
      top: 30px;
    }


  `}
`
