import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ExpandedRowContent = ({ row }) => {
  return (
    <tr>
      <td colSpan={row.cells.length}>
        <ExpandedRowBox>
          {/* 여기에 행 정보를 표시하거나 원하는 대로 커스텀하세요. */}
          <TitleBox>
            <TitleLabel>제목</TitleLabel>
            <TitleInfo>{row.original.title || '작성된 제목이 없습니다.'}</TitleInfo>
          </TitleBox>
          <ContentBox>
            <ContentLabel>내용</ContentLabel>
            <ContentInfo>{row.original.content || '작성된 내용이 없습니다.'}</ContentInfo>
          </ContentBox>
        </ExpandedRowBox>
      </td>
    </tr>
  )
}

export default ExpandedRowContent

const ExpandedRowBox = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  border: 2px #e5e5e5 solid;
`

const TitleBox = styled.div`
  width: 100%;
  height: 40px;
  display: flex;

  border-bottom: 2px #e5e5e5 solid;
`

const TitleLabel = styled.div`
  width: 170px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 17px;
  line-height: 30px;

  color: #555555;
  background: #ececec;
  border-right: 2px #e5e5e5 solid;
`

const TitleInfo = styled.div`
  width: 100%;

  font-size: 17px;
  font-weight: 400;
  line-height: 30px;

  padding-left: 20px;
  display: flex;
  align-items: center;
`

const ContentBox = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
`

const ContentLabel = styled.div`
  width: 170px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 17px;
  line-height: 30px;

  color: #555555;
  background: #ececec;

  border-right: 2px #e5e5e5 solid;
`

const ContentInfo = styled.textarea`
  width: 100%;
  padding: 10px 10px 10px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  outline: none;
  border: none;
  resize: none;
`
