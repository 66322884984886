import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import {
  CloseButton,
  CloseButtonWrap,
  PopupBody,
  PopupContent,
  PopupHeader,
  PopupImgItem,
  PopupImgWrap,
  PopupOverlay,
  PopupWrapper,
  MobilePopupWrapper,
  MobilePopupContent,
  MobilePopupBody,
  MobilePopupImgItem,
} from './PopupElements'
import Portal from '../../shared/components/Portal'

/**
 * @see : https://sycdev.tistory.com/m/19
 */

const NormalPopup = () => {
  const [popupList, setPopupList] = useState([])

  const checkVisible = title => {
    const check = localStorage.getItem(`popup-${title}`)
    const today = new Date().getDate()
    if (!check) {
      return true
    }
    return Number(check) !== today
  }

  const [visible, setVisible] = useState(true)

  // 오늘 그만보기 , 닫기 핸들러

  const closeHandler = (e, isDayClose = false) => {
    const title = e.target.getAttribute('value')

    // 오늘 그만보기 일때
    if (isDayClose) {
      // +1일 계산
      const expiredDate = new Date().getDate()
      // 로컬스토리지 저장
      localStorage.setItem(`popup-${title}`, expiredDate)
    }
    setVisible(false)
    setPopupList(prev => ({ ...prev, visible: false }))
  }

  useEffect(() => {
    // 하드코딩
    setPopupList({
      title: '01',
      imgName: `${process.env.PUBLIC_URL}/img/main/popup-01.png`,
      visible: checkVisible('01'),
    })
  }, [])

  return (
    <>
      {isMobile ? (
        <Portal elementId="normal-popup">
          <MobilePopupWrapper tabIndex="-1" visible={popupList.visible}>
            <MobilePopupContent visible={popupList.visible} tabIndex="0">
              <MobilePopupBody>
                <MobilePopupCloseBtn
                  value={popupList.title}
                  onClick={e => {
                    closeHandler(e)
                  }}
                >
                  <PopupIcon
                    value={popupList.title}
                    src={`${process.env.PUBLIC_URL}/img/main/popup-close.png`}
                    alt=""
                  />
                </MobilePopupCloseBtn>
                <PopupImgWrap>
                  <MobilePopupImgItem src={popupList.imgName} />
                </PopupImgWrap>
                <CloseButtonWrap
                  value={popupList.title}
                  onClick={e => {
                    closeHandler(e, true)
                  }}
                >
                  <CloseButton
                    value={popupList.title}
                    onClick={e => {
                      closeHandler(e, true)
                    }}
                  >
                    오늘 그만보기
                  </CloseButton>
                </CloseButtonWrap>
              </MobilePopupBody>
            </MobilePopupContent>
          </MobilePopupWrapper>
        </Portal>
      ) : (
        <Portal elementId="normal-popup">
          <PopupWrapper tabIndex="-1" visible={popupList.visible}>
            <PopupContent visible={popupList.visible} tabIndex="0">
              <PopupBody>
                <PopupCloseBtn
                  value={popupList.title}
                  onClick={e => {
                    closeHandler(e)
                  }}
                >
                  <PopupIcon
                    value={popupList.title}
                    src={`${process.env.PUBLIC_URL}/img/main/popup-close.png`}
                    alt=""
                  />
                </PopupCloseBtn>
                <PopupImgWrap>
                  <PopupImgItem src={popupList.imgName} />
                </PopupImgWrap>
                <CloseButtonWrap
                  value={popupList.title}
                  onClick={e => {
                    closeHandler(e, true)
                  }}
                >
                  <CloseButton
                    value={popupList.title}
                    onClick={e => {
                      closeHandler(e, true)
                    }}
                  >
                    오늘 그만보기
                  </CloseButton>
                </CloseButtonWrap>
              </PopupBody>
            </PopupContent>
          </PopupWrapper>
        </Portal>
      )}
    </>
  )
}

export default React.memo(NormalPopup)

export const PopupCloseBtn = styled.div`
  &:hover {
    cursor: pointer;
    background: black;
  }
  position: absolute;
  // left: 340px;
  right: 20px;
  // right: 5%;
  top: 10px;
  // bottom: 90%;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MobilePopupCloseBtn = styled.div`
  &:hover {
    cursor: pointer;
    background: black;
  }
  position: absolute;
  // left: 260px;
  right: 10px;
  top: 10px;
  // bottom: 90%;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PopupIcon = styled.img`
  width: 25px;
  height: 25px;
`
