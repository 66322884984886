import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { Field, Form } from 'react-final-form'
import {
  colorBackground,
  colorBlue,
  colorFormBorder,
  colorFormSelect,
  colorFormSelectActive,
  colorFormSelected,
  colorText,
} from '../../utils/palette'
import { borderRight } from '../../utils/directions'
import { applyCounseling } from '../../api/counseling/counseling'
import { isValidEmail } from '../../utils/commonValidate'

const MobileCustomerMtsPage = () => {
  const [personalInfo, setPersonalInfo] = useState(`
  ▶ 개인정보의 수집 및 이용목적 
  - 신규 서비스 등 최신정보 안내 및 개인맞춤서비스 제공을 위한자료 
  - 기타 원활한 양질의 서비스 제공 등 
  
  ▶ 수집하는 개인정보의 항목 
  - 이름, 전화번호, 이메일, 그외 선택항목 
  
  ▶ 개인정보의 보유 및 이용기간 
  - 원칙적으로 개인정보의 수집 또는 제공받은 목적 달성 시지체 없이 파기합니다. 
  - 다만, 원활한 서비스의 상담을 위해 상담 완료 후 내용을 3개월간 보유할 수있으며 
      전자상거래에서의 소비자보호에 관한 법률 등 타법률에 의해 보존할 필요가 있는 경우에는일정기간 보존합니다.
  `)

  const [userName, setUserName] = useState('')
  const [userPhoneNum, setUserPhoneNum] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  const [agreeChecked, setAgreeChecked] = useState(false)

  const [itemOptionValue, setItemOptionValue] = useState(null)
  const [itemOption, setItemOption] = useState([
    {
      value: 'GIFTOPTION',
      label: '선물옵션',
    },
    {
      value: 'OVERSEASGIFT',
      label: '해외선물',
    },
    {
      value: 'DOMESTICOVERSEAS',
      label: '국내 및 해외',
    },
    {
      value: 'ETC',
      label: '기타',
    },
  ])
  const onChangeItemHandler = option => {
    setItemOptionValue(option)
  }

  const resetData = () => {
    setUserName('')
    setUserPhoneNum('')
    setUserEmail('')
    setItemOptionValue(null)
    setTitle('')
    setContent('')
  }

  const [apiFlag, setApiFlag] = useState(false)

  const onSubmit = e => {
    if (!agreeChecked) {
      alert('개인정보 수집 및 이용목적에 동의해야 상담 신청이 가능합니다.')
      return
    }

    if (!userName) {
      alert('이름 항목은 필수 입력입니다.')
      return
    }
    if (!userPhoneNum) {
      alert('연락처 항목은 필수 입력입니다.')
      return
    }
    if (!userEmail) {
      alert('메일주소 항목은 필수 입력입니다.')
      return
    }

    if (!isValidEmail(userEmail)) {
      alert('메일주소가 잘못되었습니다.')
      return
    }

    if (!title) {
      alert('제목 항목은 필수 입력입니다.')
      return
    }

    if (!content) {
      alert('상담 내용 항목은 필수 입력입니다.')
      return
    }

    if (apiFlag) return

    setApiFlag(true)

    const body = {
      userName,
      userPhoneNum,
      userEmail,
      interestedEvent: !itemOptionValue?.value ? null : itemOptionValue.value,
      counselingType: 'MTS',
      title,
      content,
    }

    applyCounseling(body)
      .then(res => {
        alert('문의신청 되었습니다.')
        setApiFlag(false)
        resetData()
      })
      .catch(error => {
        alert('문의신청 실패하였습니다. 잠시후 다시 시도해주세요.')
        setApiFlag(false)
        resetData()
      })
  }

  return (
    <>
      <MenuBannerImg>
        <MenuBannerInfoWrap>
          <h2>프로그램 문의</h2>
          <p>
            잘되는 사람의 성공습관 히트스탁,
            <br />
            글로벌 시대에 맞춘 해외선물 및 국내 경제 뉴스를 제공합니다.
          </p>
        </MenuBannerInfoWrap>
      </MenuBannerImg>
      <IntroWrap>
        <IntroContentWrap>
          <IntroHeaderWrap>
            <ul>
              <li>홈</li>
              <li>프로그램 문의</li>
              <li>MTS 문의</li>
            </ul>
            <h2>MTS 문의</h2>
          </IntroHeaderWrap>
          <ContentFormWrap>
            <Form
              onSubmit={onSubmit}
              initialValues={{
                userName,
                userPhoneNum,
                userEmail,
                title,
                content,
                agreeChecked,
              }}
            >
              {({ handleSubmit, form: { reset } }) => (
                <FormContainer onSubmit={handleSubmit}>
                  <FormGroup>
                    <FormGroupLabel>이름</FormGroupLabel>
                    <FormGroupField>
                      <Field name="userName">
                        {({ input, meta }) => (
                          <FormInput
                            {...input}
                            type="text"
                            value={userName}
                            placeholder="이름을 입력해주세요."
                            onChange={e => {
                              setUserName(e.target.value)
                            }}
                          />
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>연락처</FormGroupLabel>
                    <FormGroupField>
                      <Field name="userPhoneNum">
                        {({ input, meta }) => (
                          <FormInput
                            {...input}
                            type="text"
                            value={userPhoneNum}
                            placeholder="띄어쓰기 와 - 없이 숫자만 입력해주세요."
                            onChange={e => {
                              const { value } = e.target
                              const onlyNumber = value.replace(/[^0-9]/g, '')
                              setUserPhoneNum(onlyNumber)
                            }}
                          />
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>이메일</FormGroupLabel>
                    <FormGroupField>
                      <Field name="userEmail">
                        {({ input, meta }) => (
                          <FormInput
                            {...input}
                            type="text"
                            value={userEmail}
                            placeholder="이메일를 입력해주세요."
                            onChange={e => {
                              setUserEmail(e.target.value)
                            }}
                          />
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>관심종목</FormGroupLabel>
                    <FormGroupField>
                      <Field name="interestedEvent">
                        {({ input, meta }) => (
                          <CustomSelect
                            {...input}
                            value={itemOptionValue}
                            onChange={option => {
                              onChangeItemHandler(option)
                            }}
                            options={itemOption}
                            placeholder="관심종목"
                            className="react-select"
                            classNamePrefix="react-select"
                          />
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>제목</FormGroupLabel>
                    <FormGroupField>
                      <Field name="title">
                        {({ input, meta }) => (
                          <FormInput
                            {...input}
                            type="text"
                            value={title}
                            placeholder="제목을 입력해주세요."
                            onChange={e => {
                              setTitle(e.target.value)
                            }}
                          />
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>문의내용</FormGroupLabel>
                    <FormGroupField>
                      <Field name="content">
                        {({ input, meta }) => (
                          <FormTextarea
                            {...input}
                            type="text"
                            value={content}
                            placeholder="내용을 입력해주세요."
                            onChange={e => {
                              setContent(e.target.value)
                            }}
                          />
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>

                  <FormPersonalInfoBox>
                    <FormPersonalInfoHeaderWrap>
                      <label htmlFor="check">
                        <input
                          type="checkbox"
                          id="check"
                          checked={agreeChecked}
                          onChange={e => {
                            setAgreeChecked(e.target.checked)
                          }}
                        />{' '}
                        개인정보의 수집 및 이용목적에 동의합니다.
                      </label>
                    </FormPersonalInfoHeaderWrap>
                    <FormPersonalInfo spellCheck={false} value={personalInfo} />
                  </FormPersonalInfoBox>
                  <FormButtonWrap>
                    <FormButton type="submit">문의 신청하기</FormButton>
                  </FormButtonWrap>
                </FormContainer>
              )}
            </Form>
          </ContentFormWrap>
        </IntroContentWrap>
      </IntroWrap>
    </>
  )
}

export default MobileCustomerMtsPage

const IntroWrap = styled.div`
  width: 100%;
  padding: 10px 5px;
  height: 100%;
  display: flex;
  position: relative;
`

const IntroContentWrap = styled.div`
  position: relative;
  width: 100%;
`

const IntroHeaderWrap = styled.div`
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #ddd;

  h2 {
    float: left;
    margin-top: 13px;
    margin-bottom: 9px;
    font-size: 22px;
    color: #575757;
    font-weight: 600;
    line-height: 1.6em;
  }

  ul {
    margin-top: 13px;
    margin-bottom: 9px;
    float: right;
    list-style-type: none;
  }

  ul li {
    display: inline-block;
    float: left;
    color: #8c8c8c;
    width: auto;
    font-size: 12px;
    background: url(${process.env.PUBLIC_URL}/img/main/r_arrow.gif) no-repeat left;
    line-height: 39px;
    padding: 0 10px 0 12px;

    &:first-child {
      background: url(${process.env.PUBLIC_URL}/img/main/home.gif) no-repeat center !important;
      text-indent: -9999px;
      padding: 0 15px 0 10px;
      border-left: 0px solid #ddd;
    }
  }
`

const IntroImg = styled.img`
  width: 100%;
  height: auto;
`
const MenuBannerImg = styled.div`
  width: 100%;
  height: 300px;
  background: url(${process.env.PUBLIC_URL}/img/main/menu-banner.png) no-repeat center;
  background-size: cover;
`

const MenuBannerInfoWrap = styled.div`
  position: relative;
  height: 100%;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.2); // 불투명한 배경 설정

  h2 {
    font-size: 40px;
    font-weight: 600;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    line-height: 100px;
    letter-spacing: -0.5px;
    // margin-top: 0%;
    text-shadow: 1px 1px 1px #444;
  }

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
  }
`

const ContentFormWrap = styled.div`
  width: 100%;
  height: 820px;
  margin-top: 30px;

  border: 1px solid #ddd;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  border-top: 3px #e5e5e5 solid;
  border-bottom: 1px #e5e5e5 solid;
`

const FormGroupLabel = styled.div`
  min-width: 100px;
  text-align: center;
  font-size: 15px;
  line-height: 50px;
  height: 100%;
  background-color: #f5f5f5;
  border-bottom: 1px #e5e5e5 solid;
  border-right: 1px #e5e5e5 solid;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FormGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const FormGroupField = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px #e5e5e5 solid;
`

const FormInput = styled.input`
  width: 100%;
  height: 30px;
  padding: 0 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  outline: none;
`

const FormTextarea = styled.textarea`
  width: 100%;
  height: 250px;
  padding: 10px 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  outline: none;
`

const FormButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FormButton = styled.button`
  width: 150px;
  height: 40px;
  padding: 0 10px;
  font-size: 18px;
  cursor: pointer;
  background-color: #264554;
  border: 0;
  color: #fff;
`

const FormPersonalInfoBox = styled.div`
  width: 100%;
  padding: 20px 10px;
`

const FormPersonalInfoHeaderWrap = styled.div`
  width: 100%;
  height: 20px;

  span {
    font-size: 9px;
    color: #545454;
  }

  label {
    width: 200px;
    color: #000;
    text-align: center;
    font-size: 9px;
    cursor: pointer;
    float: right;
  }
`

const FormPersonalInfo = styled.textarea`
  width: 100%;
  height: 150px;
  font-size: 12px;
  outline: none;
  resize: none;
`

export const CustomSelect = styled(Select)`
  //   width: 100%;
  width: 150px;
  height: 40px;
  font-size: 16px;

  .react-select__control {
    height: 40px;
    border-radius: 0 !important;
    transition: all 0.3s;
    &.react-select__control--is-focused,
    &:hover {
      box-shadow: none;
      .react-select__placeholder {
        color: black;
      }
    }
  }

  .react-select__input {
    height: 100%;
    // font-size: 16px;
    color: black !important;
    &.react-select__control--is-focused {
      color: red !important;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: black;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: black;
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: hidden;
    background: ${colorFormSelect};
    border: 1px solid ${colorFormBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 16px;
    // margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`
