import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { CustomTableWrap } from '../TableElements'
import ExpandedRowContent from './ExpandedRowContent'

const ReactTableDefaultBody = ({
  page,
  headerGroups,
  getTableBodyProps,
  prepareRow,
  onClickRowHandler,
  expandedRow,
}) => (
  <tbody {...getTableBodyProps()}>
    {page.length === 0 ? (
      <NoTableContentTr>
        <td colSpan={headerGroups[0].headers.length}>최근 게시물이 없습니다.</td>
      </NoTableContentTr>
    ) : (
      <>
        {page.map(row => {
          prepareRow(row)
          const isRowExpanded = expandedRow === row.id
          return (
            <>
              <tr
                {...row.getRowProps()}
                onClick={e => {
                  if (onClickRowHandler !== null) {
                    onClickRowHandler(row)
                  }
                }}
              >
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>
                    <CustomTableWrap>{cell.render('Cell')}</CustomTableWrap>
                  </td>
                ))}
              </tr>
              {isRowExpanded && <ExpandedRowContent row={row} />}
            </>
          )
        })}
      </>
    )}
  </tbody>
)

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      headers: PropTypes.arrayOf(PropTypes.shape()),
      getHeaderGroupProps: PropTypes.func,
      getFooterGroupProps: PropTypes.func,
    }),
  ).isRequired,
  onClickRowHandler: PropTypes.func,
  expandedRow: PropTypes.number,
}

ReactTableDefaultBody.defaultProps = {
  onClickRowHandler: null,
  expandedRow: null,
}

const ReactTableBody = ({ page, headerGroups, getTableBodyProps, prepareRow, onClickRowHandler, expandedRow }) => {
  return (
    <ReactTableDefaultBody
      headerGroups={headerGroups}
      page={page}
      getTableBodyProps={getTableBodyProps}
      prepareRow={prepareRow}
      onClickRowHandler={onClickRowHandler}
      expandedRow={expandedRow}
    />
  )
}

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      headers: PropTypes.arrayOf(PropTypes.shape()),
      getHeaderGroupProps: PropTypes.func,
      getFooterGroupProps: PropTypes.func,
    }),
  ).isRequired,
  onClickRowHandler: PropTypes.func,
  expandedRow: PropTypes.number,
}

ReactTableBody.defaultProps = {
  onClickRowHandler: null,
  expandedRow: null,
}

export default ReactTableBody

const NoTableContentTr = styled.tr`
  height: 100px;
  font-weight: bolder;
  font-size: 16px;
`
