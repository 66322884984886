import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Footer from './Footer'
import Header from './Header'
import MobileHeader from './MobileHeader'
import ScrollButton from './ScrollButton'
import MobileFooter from './MobileFooter'
import CounselingQuick from './CounselingQuick'
import MainTradingView from '../MainPage/MainTradingView'

// TODO  https://fourwingsy.medium.com/%ED%8C%9D%EC%97%85%EC%99%80-%EB%A9%94%EC%8B%9C%EC%A7%80-with-react-afd1923797d8
const Layout = () => {
  const navigate = useNavigate()

  return (
    <LayoutWrap>
      <MainTradingView />
      {isMobile ? <MobileHeader /> : <Header />}
      <Container $isMobile={isMobile}>
        <Outlet />
      </Container>

      {isMobile ? <MobileFooter /> : <Footer />}
      <ScrollButton isMobile={isMobile} />
      {!isMobile && <CounselingQuick />}
    </LayoutWrap>
  )
}

export default Layout

const LayoutWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

const Container = styled.div`
  width: 100%;
  min-height: 500px;
  // margin-top: ${props => (props.$isMobile ? '70px' : '74px')};

  // padding-top: 110px;
  padding-top: 44px;
`
