import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import Layout from '../containers/Layout/Layout'
import MainPage from '../containers/MainPage/MainPage'
import CompanyIntro from '../containers/Introduction/CompanyIntro'
import GuaranteePage from '../containers/Security/GuaranteePage'
import PolicyPage from '../containers/Security/PolicyPage'
import CustomerMtsPage from '../containers/Customer/CustomerMtsPage'
import CustomerHtsPage from '../containers/Customer/CustomerHtsPage'
import isLogin from '../utils/authority'
import CustomerManagePage from '../containers/Management/CustomerManagePage'
import LogIn from '../containers/Login/Login'
import MobileCompanyIntro from '../containers/Introduction/MobileCompanyIntro'
import MobileGuaranteePage from '../containers/Security/MobileGuaranteePage'
import MobilePolicyPage from '../containers/Security/MobilePolicyPage'
import MobileCustomerMtsPage from '../containers/Customer/MobileCustomerMtsPage'
import MobileCustomerHtsPage from '../containers/Customer/MobileCustomerHtsPage'
import MobileCustomerManagePage from '../containers/Management/MobileCustomerManagePage'

const PrivateRoute = ({ alertMessage = '로그인 후 이용가능합니다.' }) => {
  if (!isLogin()) {
    // return <Navigate to="/" replace state={{ alertMessage }} />
    window.location.href = '/'
  }

  return <Outlet />
}

// 긴급 점검일때는 url 막아야 함
const Router = () => {
  return (
    <AnimatePresence>
      <Routes>
        {/* 공통으로 들어갈 컴포넌트 들 */}
        <Route element={<Layout />}>
          {/* 공통으로 들어갈 컴포넌트 들 */}
          {/* 메인페이지 */}
          <Route index element={<MainPage />} />
          {/* 회사소개 */}
          <Route path="/company/intro" element={<CompanyIntro />} />
          {/* 거래안전보장제도 */}
          <Route path="/security/guarantee" element={<GuaranteePage />} />
          {/* 보안정책 */}
          <Route path="/security/policy" element={<PolicyPage />} />
          {/* HTS 문의 */}
          <Route path="/customer/hts" element={<CustomerHtsPage />} />
          {/* MTS 문의 */}
          <Route path="/customer/mts" element={<CustomerMtsPage />} />

          <Route path="/mobile/company/intro" element={<MobileCompanyIntro />} />
          <Route path="/mobile/security/guarantee" element={<MobileGuaranteePage />} />
          <Route path="/mobile/security/policy" element={<MobilePolicyPage />} />
          <Route path="/mobile/customer/hts" element={<MobileCustomerHtsPage />} />
          <Route path="/mobile/customer/mts" element={<MobileCustomerMtsPage />} />

          <Route element={<PrivateRoute />}>
            {/* 프로그램 문의 */}
            <Route path="/management/customer" element={<CustomerManagePage />} />
            <Route path="/mobile/management/customer" element={<MobileCustomerManagePage />} />
          </Route>
        </Route>

        <Route path="/manage-admin" element={<LogIn />} />
        {/* <Route path="*" element={<NotFound404 />} /> */}
      </Routes>
    </AnimatePresence>
  )
}

export default Router
