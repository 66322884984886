import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { useLocation } from 'react-router-dom'
import { Field, Form } from 'react-final-form'
import {
  colorBackground,
  colorBlue,
  colorFormBorder,
  colorFormSelect,
  colorFormSelectActive,
  colorFormSelected,
  colorText,
} from '../../utils/palette'
import { borderRight } from '../../utils/directions'
import { applySimpleCounseling } from '../../api/counseling/counseling'
import { isValidEmail } from '../../utils/commonValidate'

const CounselingQuick = () => {
  const location = useLocation()

  // true 보이기
  const [counselingQuickShow, setCounselingQuickShow] = useState(true)

  useEffect(() => {
    const path = location?.pathname
    if (path === '/customer/hts' || path === '/customer/mts' || path === '/management/customer') {
      setCounselingQuickShow(false)
    } else {
      setCounselingQuickShow(true)
    }
  }, [location])

  const [userName, setUserName] = useState('')
  const [userPhoneNum, setUserPhoneNum] = useState('')
  const [userEmail, setUserEmail] = useState('')

  const [itemOptionValue, setItemOptionValue] = useState(null)
  const [itemOption, setItemOption] = useState([
    {
      value: 'GIFTOPTION',
      label: '선물옵션',
    },
    {
      value: 'OVERSEASGIFT',
      label: '해외선물',
    },
    {
      value: 'DOMESTICOVERSEAS',
      label: '국내 및 해외',
    },
    {
      value: 'ETC',
      label: '기타',
    },
  ])
  const onChangeItemHandler = option => {
    setItemOptionValue(option)
  }

  const resetData = () => {
    setUserName('')
    setUserPhoneNum('')
    setUserEmail('')
    setItemOptionValue(null)
  }

  const [apiFlag, setApiFlag] = useState(false)

  const onSubmit = e => {
    if (!userName) {
      alert('이름 항목은 필수 입력입니다.')
      return
    }
    if (!userPhoneNum) {
      alert('연락처 항목은 필수 입력입니다.')
      return
    }
    if (!userEmail) {
      alert('메일주소 항목은 필수 입력입니다.')
      return
    }

    if (!isValidEmail(userEmail)) {
      alert('메일주소가 잘못되었습니다.')
      return
    }

    if (apiFlag) return

    setApiFlag(true)

    const body = {
      userName,
      userPhoneNum,
      userEmail,
      interestedEvent: !itemOptionValue?.value ? null : itemOptionValue.value,
    }

    applySimpleCounseling(body)
      .then(res => {
        alert('문의신청 되었습니다.')
        setApiFlag(false)
        resetData()
      })
      .catch(error => {
        alert('문의신청 실패하였습니다. 잠시후 다시 시도해주세요.')
        setApiFlag(false)
        resetData()
      })
  }

  return (
    <>
      {counselingQuickShow && (
        <CounselingWrap>
          <CounselingInnerWrap>
            <CounselingInnerLogoWrap>
              <img src={`${process.env.PUBLIC_URL}/img/main/counseling-icon.png`} alt="" />
              <CounselingInnerInfo1>해외선물 거래 당일 신청</CounselingInnerInfo1>
              <CounselingInnerInfo2>다양한 서비스를 지금 바로!</CounselingInnerInfo2>
            </CounselingInnerLogoWrap>
            <CounselingInnerFormWrap>
              <Form
                onSubmit={onSubmit}
                initialValues={{
                  userName,
                  userPhoneNum,
                  userEmail,
                }}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <CounselingInnerForm onSubmit={handleSubmit}>
                    <CounselingInnerFormGroup>
                      <Field name="userName">
                        {({ input, meta }) => (
                          <CounselingInnerFormInput
                            {...input}
                            type="text"
                            value={userName}
                            placeholder="이름"
                            onChange={e => {
                              setUserName(e.target.value)
                            }}
                          />
                        )}
                      </Field>
                    </CounselingInnerFormGroup>
                    <CounselingInnerFormGroup>
                      <Field name="userPhoneNum">
                        {({ input, meta }) => (
                          <CounselingInnerFormInput
                            {...input}
                            type="text"
                            value={userPhoneNum}
                            placeholder="연락처"
                            autoComplete="new-password"
                            onChange={e => {
                              setUserPhoneNum(e.target.value)
                            }}
                          />
                        )}
                      </Field>
                    </CounselingInnerFormGroup>
                    <CounselingInnerFormGroup>
                      <Field name="userEmail">
                        {({ input, meta }) => (
                          <CounselingInnerFormInput
                            {...input}
                            type="text"
                            value={userEmail}
                            placeholder="메일주소"
                            onChange={e => {
                              setUserEmail(e.target.value)
                            }}
                          />
                        )}
                      </Field>
                    </CounselingInnerFormGroup>
                    <CounselingInnerFormGroup>
                      <Field name="interestedEvent">
                        {({ input, meta }) => (
                          <CustomSelect
                            {...input}
                            value={itemOptionValue}
                            onChange={option => {
                              onChangeItemHandler(option)
                            }}
                            options={itemOption}
                            placeholder="관심종목"
                            className="react-select"
                            classNamePrefix="react-select"
                            menuPlacement="top"
                          />
                        )}
                      </Field>
                    </CounselingInnerFormGroup>
                    <CounselingInnerFormButton type="submit">문의 신청하기</CounselingInnerFormButton>
                  </CounselingInnerForm>
                )}
              </Form>
            </CounselingInnerFormWrap>
          </CounselingInnerWrap>
        </CounselingWrap>
      )}
    </>
  )
}

export default CounselingQuick

const CounselingWrap = styled.div`
  width: 100%;
  height: 100px;

  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 122;

  background-color: #000;

  display: flex;
  align-items: center;
`

const CounselingInnerWrap = styled.div`
  width: 1260px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
`

const CounselingInnerLogoWrap = styled.div`
  min-width: 350px;
  position: relative;
  padding: 5px;

  img {
    width: 40px;

    position: absolute;
    top: 5px;
    right: -20px;
  }
`

const CounselingInnerInfo1 = styled.p`
  font-size: 30px;
  line-height: 35px;
  font-weight: 300;
  text-align: center;
  color: white;
`

const CounselingInnerInfo2 = styled.p`
  font-size: 30px;
  line-height: 35px;
  font-weight: 500;
  text-align: center;
  color: white;
`

const CounselingInnerFormWrap = styled.div`
  width: 100%;
  height: 100%;
  //   border: 1px solid yellow;

  display: flex;

  padding: 30px 5px 30px 0;
`

const CounselingInnerForm = styled.form`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 0 5px;
  width: 100%;
  height: 100%;
`

const CounselingInnerFormGroup = styled.div`
  width: 150px;
  display: flex;
  align-items: center;
`

const CounselingInnerFormInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 0 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  border: transparent;
  //   background: transparent;
  outline: none;
`

const CounselingInnerFormButton = styled.button`
  width: 150px;
  height: 40px;
  padding: 0 10px;
  font-size: 18px;
  cursor: pointer;
  background-color: #264554;
  border: 0;
  color: #fff;
`

export const CustomSelect = styled(Select)`
  width: 100%;
  height: 40px;
  font-size: 16px;

  .react-select__control {
    height: 40px;
    border-radius: 0 !important;
    transition: all 0.3s;
    // border: 1px solid ${colorFormBorder};
    // background-color: ${colorFormSelect};
    &.react-select__control--is-focused,
    &:hover {
      //   border: solid 3px;
      //   border-color: ${colorFormSelectActive} !important;
      box-shadow: none;
      //   background: ${colorFormSelected};
      .react-select__placeholder {
        color: black;
      }
    }
  }

  .react-select__input {
    height: 100%;
    // font-size: 16px;
    color: black !important;
    &.react-select__control--is-focused {
      color: red !important;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: black;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: black;
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: hidden;
    background: ${colorFormSelect};
    border: 1px solid ${colorFormBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 16px;
    // margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`
