import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSearchCounselingAdmin } from '../../api/counseling/counseling'
import CustomerManageData from './CustomerManageData'
import ReactTableBase from '../../shared/components/table/ReactTableBase'

const CustomerManagePage = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [expandedRow, setExpandedRow] = useState(null)

  const [params, setParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
    setExpandedRow(null)
  }, [page, size])

  const { totalElement, content, fetchSearchCounselingAdmin } = useSearchCounselingAdmin(params)

  const [customerManageData, setCustomerManageData] = useState(CustomerManageData(content))

  useEffect(() => {
    setCustomerManageData(CustomerManageData(content))
  }, [content])

  const [rows, setData] = useState(customerManageData.tableRowsData)
  useEffect(() => {
    setData(customerManageData.tableRowsData)
  }, [customerManageData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {
    if (expandedRow === rowValue.id) {
      setExpandedRow(null)
    } else {
      setExpandedRow(rowValue.id)
    }
  }

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
    // 확장할 ROW
    expandedRow,
  }

  return (
    <>
      <MenuBannerImg>
        <MenuBannerInfoWrap>
          <h2>관리 페이지</h2>
          <p>관리자 전용 페이지입니다</p>
        </MenuBannerInfoWrap>
      </MenuBannerImg>
      <IntroWrap>
        <LeftSideMenuWrap>
          <h3>관리</h3>
          <LeftSideMenu>
            <LeftSideSubMenu>
              <a href="/management/customer">상담 관리</a>
            </LeftSideSubMenu>
          </LeftSideMenu>
          <LeftSideMenuInfoBox>
            <LeftSideMenuInfo1>CUSTOMER</LeftSideMenuInfo1>
            <LeftSideMenuInfo2>
              문의전화를 주시면 친절히 <br /> 상담하여 드립니다.
            </LeftSideMenuInfo2>
            <LeftSideMenuInfo3>Tel. 010-5804-5090</LeftSideMenuInfo3>
          </LeftSideMenuInfoBox>
        </LeftSideMenuWrap>
        <IntroContentWrap>
          <IntroHeaderWrap>
            <ul>
              <li>홈</li>
              <li>관리</li>
              <li>상담 관리</li>
            </ul>
            <h2>상담 관리</h2>
          </IntroHeaderWrap>
          <ContentTableWrap>
            <ReactTableBase columns={customerManageData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          </ContentTableWrap>
        </IntroContentWrap>
      </IntroWrap>
    </>
  )
}

export default CustomerManagePage

const ContentTableWrap = styled.div`
  width: 100%;
  padding-top: 40px;
  //   border: 1px solid red;
`

const IntroWrap = styled.div`
  width: 1260px;
  margin: 10px auto 60px auto;
  height: 100%;
  display: flex;
  position: relative;
`

const IntroContentWrap = styled.div`
  position: relative;
  float: right;
  width: 1000px;
  margin-left: auto;
`

const IntroHeaderWrap = styled.div`
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #ddd;

  h2 {
    float: left;
    margin-top: 13px;
    margin-bottom: 9px;
    font-size: 22px;
    color: #575757;
    font-weight: 600;
    line-height: 1.6em;
  }

  ul {
    margin-top: 13px;
    margin-bottom: 9px;
    float: right;
    list-style-type: none;
  }

  ul li {
    display: inline-block;
    float: left;
    color: #8c8c8c;
    width: auto;
    font-size: 12px;
    background: url(${process.env.PUBLIC_URL}/img/main/r_arrow.gif) no-repeat left;
    line-height: 39px;
    padding: 0 10px 0 12px;

    &:first-child {
      background: url(${process.env.PUBLIC_URL}/img/main/home.gif) no-repeat center !important;
      text-indent: -9999px;
      padding: 0 15px 0 10px;
      border-left: 0px solid #ddd;
    }
  }
`

const IntroImg = styled.img`
  width: 100%;
  height: auto;
  margin-left: auto;
`
const MenuBannerImg = styled.div`
  width: 100%;
  height: 400px;
  background: url(${process.env.PUBLIC_URL}/img/main/menu-banner.png) no-repeat center;
  background-size: cover;
`

const MenuBannerInfoWrap = styled.div`
  position: relative;
  height: 100%;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.2); // 불투명한 배경 설정

  h2 {
    font-size: 40px;
    font-weight: 600;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    line-height: 100px;
    letter-spacing: -0.5px;
    // margin-top: 0%;
    text-shadow: 1px 1px 1px #444;
  }

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
  }
`

const LeftSideMenuWrap = styled.div`
  position: relative;
  float: left;
  width: 250px;
  padding: 0 10px;
  height: 450px;
  display: flex;
  flex-direction: column;

  h3 {
    float: left;
    width: 95%;
    margin: 13px 0 9px 5px;
    font-size: 24px;
    color: #575757;
    font-weight: 600;
    line-height: 1.6em;
  }
`

const LeftSideMenu = styled.ul`
  position: relative;
  float: left;
  clear: both;
  width: 100%;
  border-top: 1px solid #222;
  list-style-type: none;
`

const LeftSideSubMenu = styled.li`
  display: block;
  float: left;
  width: 100%;
  clear: both;
  border-bottom: 1px solid #d8d8d8;
  list-style-type: none;

  a {
    display: block;
    float: left;
    width: 100%;
    padding-left: 18px;
    font-size: 17px;
    line-height: 43px;
    color: #555555;
    background: #ececec;
  }
`

const LeftSideMenuInfoBox = styled.div`
  position: relative;
  float: left;
  clear: both;
  width: 100%;
  height: 160px;
  background: #363331;
  margin-top: 100px;
  padding-left: 20px;
`

const LeftSideMenuInfo1 = styled.p`
  width: 100%;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 50px;
`
const LeftSideMenuInfo2 = styled.p`
  width: 100%;
  text-align: left;
  color: #ffffff;
  font-size: 10.5px;
  line-height: 15px;
  margin-top: 5px;
`
const LeftSideMenuInfo3 = styled.p`
  width: 100%;
  text-align: left;
  color: #ffffff;
  font-size: 20px;
  line-height: 40px;
  font-weight: 600;
  letter-spacing: -1px;
  margin-top: 15px;
`
