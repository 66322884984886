// 상담 타입 - 간단 상담, HTS, MTS
export const CounselingType = {
  SIMPLE: '간편',
  HTS: 'HTS',
  MTS: 'MTS',
}

// 상담 상태 - 상담 완료
export const CounselingStatus = {
  COMPLETE: '신청완료',
}

// 관심 종목
export const CounselingInterestedEvent = {
  GIFTOPTION: '선물옵션',
  OVERSEASGIFT: '해외선물',
  DOMESTICOVERSEAS: '국내 및 해외',
  ETC: '기타',
}
